<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title>
        <menu-title title="View License"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <licenses-list
            :customerid="subcustomer.customerid"
            :selection-enabled="false"
          ></licenses-list>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import menuTitle from "../helpers/menu_title.vue";
import licensesList from "../licenses_list.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ViewLicensesDialog",
  components: {
    menuTitle,
    licensesList,
  },
  data() {
    return {};
  },
  props: {
    value: Boolean,
    subcustomer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({}),
  },
  computed: {
    ...mapGetters({
      customerlicensesBySubcustomerIdActiveLicenses:
        "customerlicensesBySubcustomerIdActiveLicenses",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>