<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    persistent
  >
    <v-card>
      <card-title-menu :title="contact ? 'Edit Contact' : 'New Contact'"></card-title-menu>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              outlined
              hide-details
              v-model="tmpContact.forename"
              label="Forename*"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              outlined
              hide-details
              v-model="tmpContact.surname"
              label="Surname*"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              outlined
              hide-details
              v-model="tmpContact.email"
              label="E-mail*"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              outlined
              hide-details
              v-model="tmpContact.phone"
              label="Phone*"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              v-model="tmpContact.position"
              label="Position"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              v-model="tmpContact.notes"
              label="Additional Notes"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          class="ml-2"
          @click="dialog=false"
        >
          <span>Close</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-2"
          color="#b31616"
          @click="contact ? patchContact() : postContact()"
        >
          <span>{{ contact ? "Save" : "Add"}}</span>
        </v-btn>
        <!--
        <v-btn @click="debug">Debug</v-btn>
        -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cardTitleMenu from "./helpers/card_title_menu.vue";
export default {
  name: "newContactDialog",
  components: {
    cardTitleMenu,
  },
  props: {
    value: Boolean,
    customer: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    changed: -1,
    tmpContact: {},
    requiredRule: (value) => !!value || "Required",
  }),
  updated() {
    this.changed++;
  },
  computed: {
    ...mapGetters({
      newContact: "newContact",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      post: "postContact",
      patch: "patchContact",
      showSnackbar: "showSnackbar",
      fetchContactsAsync: "fetchContactsAsync",
    }),
    debug: function () {
      console.log(this.tmpContact);
    },
    patchContact: function () {
      if (this.changed == 0) {
        this.dialog = false;
      }
      this.patch(this.tmpContact).then((error) => {
        if (error) {
          this.showSnackbar(
            "Contact could not be updated. Error: {0}".format(error)
          );
        } else {
          this.showSnackbar("Contact has been updated");
          this.dialog = false;
        }
      });
    },
    postContact: function () {
      this.tmpContact.customerid = this.customer.customerid;
      this.post(this.tmpContact).then((error) => {
        if (error) {
          this.showSnackbar(
            "Contact could not be added. Error: {0}".format(error)
          );
        } else {
          this.showSnackbar("Contact has been added");
          this.dialog = false;
        }
      });
    },
  },
  mounted() {
    if (this.contact) {
      this.tmpContact = JSON.parse(JSON.stringify(this.contact));
    } else {
      this.tmpContact = JSON.parse(JSON.stringify(this.newContact));
    }
  },
};
</script>

<style>
</style>