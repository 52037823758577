<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <card-title title="Activate Portal Acess"></card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details="auto"
              clearable
              :rules="[requiredRule]"
              v-model="username"
              label="Username/Email*"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details="auto"
              :rules="[requiredRule]"
              v-model="password"
              label="Password*"
              append-icon="mdi-dice-6-outline"
              @click:append="randomizePassword"
              loading
            >
              <template v-slot:progress>
                <v-progress-linear
                  stream
                  absolute
                  height="6"
                  :color="getComplexityColor"
                  :value="getComplexityValue"
                ></v-progress-linear>
              </template>
            </v-text-field>
          </v-col>
          <!--
          <v-col cols="4">
            <v-checkbox
              color="#b31616"
              label="Notify via email?"
              v-model="sendEmail"
              @click="sendEmailAddress=username"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="6"
            v-if="sendEmail"
          >
            <v-checkbox
              color="#b31616"
              label="Include password in mail?"
              v-model="includePassword"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            v-if="sendEmail"
          >
            <v-text-field
              outlined
              hide-details
              v-model="sendEmailAddress"
              label="Notification recipient"
            ></v-text-field>
          </v-col>
          -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-2" outlined @click="dialog = false">
          <span>Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          outlined
          color="primary"
          @click="activate"
          :disabled="!(password && username)"
        >
          <span>Activate</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import cardTitle from "./helpers/card_title_menu.vue";
export default {
  name: "activatePortalAccessDialog",
  components: {
    cardTitle,
  },
  props: {
    value: {
      type: Boolean,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getComplexityColor() {
      var complexity = this.getComplexity();
      return ["#fc0303", "#fc8c03", "#fcdb03", "#c6fc03", "#5efc03"][
        complexity - 1
      ];
    },
    getComplexityValue() {
      var complexity = this.getComplexity();
      return (100 / 5) * complexity;
    },
  },
  data: () => ({
    username: "",
    password: "",
    requiredRule: (value) => !!value || "Required",
    sendEmail: false,
    sendEmailAddress: "",
    includePassword: false,
  }),
  methods: {
    ...mapActions({
      activateAccess: "activateAccess",
      showSnackbar: "showSnackbar",
    }),
    activate: function () {
      console.log("activating...");
      console.log(this.contact);

      this.activateAccess({
        name: this.contact.forename + " " + this.contact.surname,
        email: this.username,
        password: this.password,
        customerid: this.contact.customerid,
        contactid: this.contact.id,
      }).then((error) => {
        if (error) {
          this.showSnackbar(
            `Access could not be activated. Error: ${error[0]}`
          );
        } else {
          this.showSnackbar("Access has been granted");
          this.dialog = false;
        }
      });
    },
    getRandomPassword: function () {
      const lowercase = "abcdefghijklmnopqrstuvwxyz";
      const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numbers = "0123456789";
      const special = "^!\"§$%&/()=?`{[]}\\#'+*~-_.:,;";
      var characters = lowercase + uppercase + numbers + special;
      var res = "";
      for (let i = 0; i < 15; i++) {
        res += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return res;
    },
    randomizePassword: function () {
      do {
        var res = this.getRandomPassword();
      } while (this.getComplexity(res) < 5);
      this.password = res;
    },
    getComplexity: function (password = null) {
      let string = this.password;
      if (password) string = password;

      var retVal = 1;

      const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ";
      const numbers = "0123456789";
      const special = "^!\"§$%&/()=?`{[]}\\#'+*~-_.:,;°µ";

      for (let i = 0; i < uppercase.length; i++) {
        if (string.includes(uppercase[i])) {
          retVal += 1;
          break;
        }
      }
      for (let i = 0; i < numbers.length; i++) {
        if (string.includes(numbers[i])) {
          retVal += 1;
          break;
        }
      }
      for (let i = 0; i < special.length; i++) {
        if (string.includes(special[i])) {
          retVal += 1;
          break;
        }
      }
      if (string.length >= 8) {
        retVal += 1;
      }
      return retVal;
    },
  },
  mounted() {
    this.username = this.contact.email;
  },
};
</script>

<style>
</style>