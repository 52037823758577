<template>
  <v-card>
    <v-card-title>
      <menu-title title="Partners" class="mt-6">
        <v-btn outlined class="mt-0" @click="partnerDialog = true">
          New Partner
        </v-btn>
      </menu-title>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            label="Search..."
            prepend-icon="mdi-account-search-outline"
            class="pl-2"
          >
          </v-text-field>
        </v-col>

        <v-col cols="3">
          <v-switch
            v-model="showWithoutLicenses"
            :label="
              showWithoutLicenses
                ? 'Showing Partners without licenses'
                : 'Hiding Partners without licenses'
            "
          ></v-switch>
        </v-col>

        <v-col cols="2">
          <v-select
            :items="sortItems"
            item-text="text"
            item-value="value"
            v-model="sortBy"
            outlined
            label="Sort by"
            @change="reorderCustomers()"
          ></v-select>
        </v-col>

        <v-col cols="1">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                outlined
                medium
                v-on="on"
                v-bind="attrs"
                @click="
                  ascendingOrder = !ascendingOrder;
                  reorderCustomers();
                "
              >
                <v-icon v-if="ascendingOrder">mdi-sort-ascending</v-icon>
                <v-icon v-else>mdi-sort-descending</v-icon>
              </v-btn>
            </template>
            <span v-if="ascendingOrder">Ascending</span>
            <span v-else>Descending</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" align="left">
          <v-label>
            {{ customercount }}
          </v-label>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" align="right">
          <v-label>
            {{ customershowncount }}
          </v-label>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <v-list v-for="customer in processedCustomers" :key="customer.id">
        <customercard
          :key="'empty'"
          keep-alive
          :customer="customer"
          :search="search"
          :hideWithoutLicenses="!showWithoutLicenses"
        >
        </customercard>
      </v-list>
    </v-card-text>

    <v-dialog v-model="partnerDialog" persistent>
      <partner-dialog @done="partnerDialog = false"></partner-dialog>
    </v-dialog>
  </v-card>
</template>
<script>
import partnerDialog from "../../components/partners_partner_dialog.vue";
import menuTitle from "../../components/helpers/menu_title.vue";
import customercard from "../../components/partners_partner_card.vue";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "Partners",
  components: {
    menuTitle,
    customercard,
    partnerDialog,
  },
  computed: {
    ...mapGetters({
      customers: "allCustomers",
      subcustomersByCustomerId: "subcustomersByCustomer",
      customerlicensesBySubcustomerId: "customerlicensesBySubcustomerId",
      customerlicensesByCustomerId: "customerlicensesByCustomerId",
      userloginsByUserid: "userloginsByUserid",
    }),
    customercount: function () {
      return this.customers.length + " partners total";
    },
    customershowncount: function () {
      return (
        this.customers.filter((c) =>
          c.name.toLowerCase().includes(this.search.toLowerCase())
        ).length + " shown"
      );
    },
  },
  watch: {
    customers: function (newArray) {
      this.processedCustomers = JSON.parse(JSON.stringify(newArray));
      this.reorderCustomers();
    },
  },
  props: {},
  data() {
    var self = this;
    return {
      processedCustomers: [],
      search: "",
      ascendingOrder: true,
      showWithoutLicenses: true,
      sortBy: "name",
      sortItems: [
        {
          text: "ID",
          value: "id",
          compare: self.compareIds,
        },
        {
          text: "Name",
          value: "name",
          compare: self.compareStrings,
        },
        {
          text: "Customer count",
          value: "customerCount",
          compare: self.compareCustomerCount,
        },
        {
          text: "Licenses sold",
          value: "licensesSold",
          compare: self.compareLicensesSold,
        },
        {
          text: "Last login",
          value: "lastLogin",
          compare: self.compareLastLogin,
        },
      ],
      partnerDialog: false,
    };
  },
  methods: {
    ...mapActions({
      fetchCustomers: "fetchCustomers",
      fetchCustomersAsync: "fetchCustomersAsync",
      fetchUserloginsAsync: "fetchUserloginsAsync",
    }),
    reorderCustomers: function () {
      this.processedCustomers.sort(
        this.sortItems.find((item) => item.value == this.sortBy).compare
      );
    },
    determinOrder(a, b) {
      return this.ascendingOrder ? a - b : b - a;
    },
    compareStrings(a, b) {
      var name_a = a.name.toLowerCase();
      var name_b = b.name.toLowerCase();
      if (this.ascendingOrder) {
        return name_a > name_b ? 1 : name_b > name_a ? -1 : 0;
      } else {
        return name_a < name_b ? 1 : name_b < name_a ? -1 : 0;
      }
    },
    compareIds(a, b) {
      return this.determinOrder(a.customerid, b.customerid);
    },
    compareCustomerCount: function (a, b) {
      var amount_a = this.subcustomersByCustomerId(a.customerid).length ?? 0;
      var amount_b = this.subcustomersByCustomerId(b.customerid).length ?? 0;
      return this.determinOrder(amount_a, amount_b);
    },
    compareLicensesSold: function (a, b) {
      var amount_a =
        this.customerlicensesByCustomerId(a.customerid).length ?? 0;
      var amount_b =
        this.customerlicensesByCustomerId(b.customerid).length ?? 0;
      return this.determinOrder(amount_a, amount_b);
    },
    compareLastLogin: function (a, b) {
      var time_a = this.userloginsByUserid(a.customerid)[0].timestamp ?? 0;
      var time_b = this.userloginsByUserid(b.customerid)[0].timestamp ?? 0;
      return this.determinOrder(time_a, time_b);
    },
  },
  async mounted() {
    await this.fetchCustomersAsync();
    await this.fetchUserloginsAsync();
    this.processedCustomers = JSON.parse(JSON.stringify(this.customers));
    this.reorderCustomers();
  },
};
</script>
<style scoped>
.nopad {
  padding-left: 0px !important;
}
.customer_title {
  font-size: 2.2rem;
  color: #b31616;
}
</style>