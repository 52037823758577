<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    persistent
  >
    <v-card>
      <v-card-title>
        <menu-title title="Partner Contacts">
          <v-btn
            color="primary"
            outlined
            @click="selectedContact=null;newContactDialog=true"
          >
            <v-icon>mdi-plus</v-icon>
            <span>New Contact</span>
          </v-btn>
        </menu-title>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <td align="left">Forename</td>
              <td align="left">Surname</td>
              <td align="left">Email</td>
              <td align="left">Phone</td>
              <td align="left">Position</td>
              <td align="right">Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="contact in contactsByCustomerId(customer.customerid)"
              :key="contact.id"
            >
              <td align="left">{{ contact.forename }}</td>
              <td align="left">{{ contact.surname }}</td>
              <td align="left">{{ contact.email }}</td>
              <td align="left">{{ contact.phone }}</td>
              <td align="left">{{ contact.position }}</td>
              <td align="right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!userByContactId(contact.id)"
                      icon
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      class="ml-2"
                      color="primary"
                      @click="selectedContact=contact;activatePortalAccessDialog=true;"
                    >
                      <v-icon>mdi-shield-lock-open-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Activate Portal Acess</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      class="ml-2"
                      color="primary"
                      @click="selectedContact=contact;acceptDialog=true"
                    >
                      <v-icon>mdi-delete-empty-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Contact</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      class="ml-2"
                      color="primary"
                      @click="selectedContact=contact;newContactDialog=true"
                    >
                      <v-icon>mdi-cog-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Contact</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          class="ml-2"
          color="#b31616"
          @click="dialog=false"
        >
          <span>Close</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <new-contact-dialog
      v-model="newContactDialog"
      v-if="newContactDialog"
      :customer="customer"
      :contact="selectedContact"
    ></new-contact-dialog>

    <activate-portal-access-dialog
      v-model="activatePortalAccessDialog"
      v-if="activatePortalAccessDialog"
      :contact="selectedContact"
    >
    </activate-portal-access-dialog>

    <accept-dialog
      v-model="acceptDialog"
      v-if="acceptDialog"
      title="Delete Contact?"
      :text="deletionText"
      @accepted="deleteContact();selectedContact=null"
      @rejected="selectedContact=null"
    ></accept-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
import newContactDialog from "./partners_partner_contacts_newcontact_dialog.vue";
import acceptDialog from "./helpers/generic_accept_dialog.vue";
import activatePortalAccessDialog from "./partners_partner_contacts_activate_dialog.vue";
export default {
  name: "contactsDialog",
  components: {
    menuTitle,
    newContactDialog,
    acceptDialog,
    activatePortalAccessDialog,
  },
  props: {
    value: Boolean,
    customer: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    newContactDialog: false,
    activatePortalAccessDialog: false,
    acceptDialog: false,
    selectedContact: null,
  }),
  computed: {
    ...mapGetters({
      allContacts: "allContacts",
      userByContactId: "userByContactId",
      contactsByCustomerId: "contactsByCustomerId",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    deletionText: function () {
      var str = "Are you sure you want to delete ";
      str +=
        '"' +
        this.selectedContact.forename +
        " " +
        this.selectedContact.surname +
        '" ';
      str += 'of Partner "' + this.customer.name + '"?';
      return str;
    },
  },
  methods: {
    ...mapActions({
      delete: "deleteContact",
      showSnackbar: "showSnackbar",
      fetchContactsAsync: "fetchContactsAsync",
      fetchUsersAsync: "fetchUsersAsync",
    }),
    deleteContact: function () {
      console.log("deleting...");
      console.log(this.selectedContact);
      this.delete(this.selectedContact).then((error) => {
        if (error) {
          this.showSnackbar(
            "Contact could not be deleted. Error {0}".format(error)
          );
        } else {
          this.showSnackbar("Contact has been deleted");
        }
      });
    },
  },
  async mounted() {
    await this.fetchUsersAsync();
    await this.fetchContactsAsync();
  },
};
</script>

<style>
</style>