<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    persistent
  >
    <v-card>
      <v-card-title>
        <menu-title title="Portal Activation"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              clearable
              outlined
              hide-details
              label="Username / Email"
              v-model="username"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              label="Password"
              v-model="password"
              append-icon="mdi-dice-6-outline"
              @click:append="randomizePassword"
              loading
            >
              <template v-slot:progress>
                <v-progress-linear
                  stream
                  absolute
                  height="6"
                  :color="getComplexityColor"
                  :value="getComplexityValue"
                ></v-progress-linear>
              </template>

            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              disabled
              color="#b31616"
              label="Send confirmation mail?"
              v-model="sendConfirmation"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            v-if="sendConfirmation"
          >
            <v-text-field
              disabled
              outlined
              hide-details
              v-model="confirmationEmail"
              label="Confirmation Recipient"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          class="ml-2"
          @click="dialog=false"
        >
          <span>Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="#b31616"
          class="mr-2"
          @click="activate"
        >
          <span>Activate</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import menuTitle from "./helpers/menu_title.vue";
export default {
  name: "activationDialog",
  components: {
    menuTitle,
  },
  props: ["value"],
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    getComplexityColor() {
      var complexity = this.getComplexity();
      return ["#fc0303", "#fc8c03", "#fcdb03", "#c6fc03", "#5efc03"][
        complexity - 1
      ];
    },
    getComplexityValue() {
      var complexity = this.getComplexity();
      return (100 / 5) * complexity;
    },
  },
  data: () => ({
    username: "",
    password: "",
    confirmationEmail: "",
    sendConfirmation: true,
  }),
  methods: {
    getRandomPassword: function () {
      const lowercase = "abcdefghijklmnopqrstuvwxyz";
      const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numbers = "0123456789";
      const special = "^!\"§$%&/()=?`{[]}\\#'+*~-_.:,;";
      var characters = lowercase + uppercase + numbers + special;
      var res = "";
      for (let i = 0; i < 15; i++) {
        res += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return res;
    },
    randomizePassword: function () {
      do {
        var res = this.getRandomPassword();
      } while (this.getComplexity(res) < 5);
      this.password = res;
    },
    getComplexity: function (password = null) {
      let string = this.password;
      if (password) string = password;

      var retVal = 1;

      const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ";
      const numbers = "0123456789";
      const special = "^!\"§$%&/()=?`{[]}\\#'+*~-_.:,;°µ";

      for (let i = 0; i < uppercase.length; i++) {
        if (string.includes(uppercase[i])) {
          retVal += 1;
          break;
        }
      }
      for (let i = 0; i < numbers.length; i++) {
        if (string.includes(numbers[i])) {
          retVal += 1;
          break;
        }
      }
      for (let i = 0; i < special.length; i++) {
        if (string.includes(special[i])) {
          retVal += 1;
          break;
        }
      }
      if (string.length >= 8) {
        retVal += 1;
      }
      return retVal;
    },
    activate: function () {
      console.log("activating access...");
      if (this.sendConfirmation) {
        
      }
    },
  },
};
</script>

<style>
</style>