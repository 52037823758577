<template>
  <v-card v-if="isShown()" outlined class="custom_card">
    <v-card-title align="left" class="custom_card_title">
      {{ customer.name }}
      <v-spacer></v-spacer>

      <v-menu offset-y open-on-hover transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-on="on" v-bind="attrs">
            Manage&nbsp;
            <v-icon>mdi-arrow-down-drop-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link class="menu_item" @click="partnerDialog = true">
            <span>Edit Partner</span>
          </v-list-item>
          <v-list-item link class="menu_item" @click="contactsDialog = true">
            <span>Contacts</span>
          </v-list-item>
          <v-list-item
            link
            class="menu_item"
            @click="disableDialog = true"
            disabled
          >
            <span>Disble Partner</span>
          </v-list-item>
          <v-list-item
            link
            class="menu_item"
            @click="deleteDialog = true"
            v-if="canDeletePartner()"
          >
            <span>Delete Partner</span>
          </v-list-item>
          <!--
          <v-list-item
            link
            class="menu_item"
            @click="activationDialog=true"
          >
            <span>Activate Portal Access</span>
          </v-list-item>
          -->
          <v-list-item
            @click="
              customerDialog = true;
              addCustomerAsAdmin();
            "
            link
            class="menu_item"
          >
            <span>Add Customer</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <!--
      TODO: Allow one primary contact to be set and display it's information here
    <v-card-subtitle align="left">
      <v-row>
        <v-col cols="3">
          <label>Customer ID:&nbsp;</label>
          <label><b>{{ customer.customerid }}</b></label>
        </v-col>
        <v-col cols="3">
          <label>Contact:&nbsp;</label>
          <label><b>{{ customer.contact }}</b></label>
        </v-col>
        <v-col cols="3">
          <label>E-Mail:&nbsp;</label>
          <label><b>{{ customer.email }}</b></label>
        </v-col>
      </v-row>
    </v-card-subtitle>
    -->

    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col cols="3" align="left">
          <v-label>{{ getTotalCustomersString }}</v-label>
        </v-col>
        <v-col cols="3" align="left">
          <v-label>{{ getTotalLicensesSoldString }}</v-label>
        </v-col>
        <v-col cols="3" align="left">
          <v-label>{{ getTotalRevenueString }}</v-label>
        </v-col>
        <v-col cols="3" align="left" v-if="allTemplates">
          <v-label>{{ getApplicationsString }}</v-label>
        </v-col>
      </v-row>

      <v-container v-if="expanded" fluid>
        <v-row>
          <v-col cols="stretch">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="stretch">
            <v-data-table
              :items-per-page="-1"
              hide-default-footer
              :items="getSubcustomerLicenseStats"
              :headers="headers"
              item-key="customerid"
              show-select
              v-model="selected"
            >
              <template v-slot:item.maintenance="{ item }">
                <v-icon color="#42e629" v-if="item.maintenance">
                  mdi-check
                </v-icon>
                <v-icon color="primary" v-else>mdi-close</v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small color="primary" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small color="primary" @click="addLicense(item)">
                  mdi-license
                </v-icon>
                <v-icon small color="primary" @click="viewLicenses(item)">
                  mdi-eye-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col v-if="selected.length > 0" align="center">
          <v-btn
            large
            outlined
            class="mt-2"
            color="primary"
            @click="deleteCustomerDialog = true"
          >
            <v-icon> mdi-delete-outline </v-icon>
            Delete selected Customers
          </v-btn>
        </v-col>
        <v-col cols="stretch" align="center">
          <v-btn
            large
            outlined
            class="mt-2"
            @click="
              expanded = !expanded;
              selected = [];
            "
          >
            <span v-if="expanded">Hide Partner Information</span>
            <span v-else>Show Partner Information</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- 
    <v-card-actions>
      
      <v-btn
        outlined
        @click="debug"
      >
        <span>Debug</span>
      </v-btn>
     
    </v-card-actions> -->

    <v-dialog v-model="partnerDialog" max-width="1100" persistent>
      <partner-dialog
        :customer="customer"
        @done="partnerDialog = false"
      ></partner-dialog>
    </v-dialog>

    <accept-dialog
      v-model="deleteCustomerDialog"
      v-if="deleteCustomerDialog"
      title="Delete Customers?"
      text="Are you sure you want to delete these Customers?"
      @accepted="deleteSubustomer"
    >
    </accept-dialog>

    <accept-dialog
      v-model="deleteDialog"
      v-if="deleteDialog"
      title="Delete Partner?"
      text="Are you sure you want to delete this partner?"
      @accepted="deletePartner"
    >
    </accept-dialog>

    <accept-dialog
      v-model="disableDialog"
      v-if="disableDialog"
      title="Disble Partner?"
      text="Are you sure you want to disable this partner?"
      @accepted="disablePartner"
    >
    </accept-dialog>

    <activation-dialog
      v-model="activationDialog"
      v-if="activationDialog"
    ></activation-dialog>

    <contacts-dialog
      v-model="contactsDialog"
      v-if="contactsDialog"
      :customer="customer"
    ></contacts-dialog>

    <customer-dialog
      v-if="customerDialog"
      v-model="customerDialog"
      :subcustomer="subcustomer"
      @done="
        subcustomer = null;
        customerDialog = false;
      "
    ></customer-dialog>

    <license-request-dialog
      v-if="licenseRequestDialog"
      v-model="licenseRequestDialog"
      :subcustomer="subcustomer"
      @done="licenseRequestDialog = false"
    ></license-request-dialog>

    <view-licenses-dialog
      v-if="viewLicensesDialog"
      v-model="viewLicensesDialog"
      :dialog="viewLicensesDialog"
      :subcustomer="subcustomer"
      @done="viewLicensesDialog = false"
    ></view-licenses-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import acceptDialog from "./helpers/generic_accept_dialog.vue";
import partnerDialog from "./partners_partner_dialog.vue";
import activationDialog from "./partners_partner_activation_dialog.vue";
import contactsDialog from "./partners_partner_contacts_dialog.vue";
import customerDialog from "./user/customer_dialog.vue";
import licenseRequestDialog from "./user/customer_license_request_dialog.vue";
import viewLicensesDialog from "./admin/view_licenses_dialog.vue";

export default {
  name: "customercard",
  components: {
    acceptDialog,
    partnerDialog,
    activationDialog,
    contactsDialog,
    customerDialog,
    licenseRequestDialog,
    viewLicensesDialog,
  },
  computed: {
    ...mapGetters({
      allCustomerlicenses: "allCustomerlicenses",
      customers: "allCustomers",
      allApplications: "allApplications",
      applicationById: "applicationById",
      templateById: "templateById",
      modules: "allModules",
      licenses: "allLicenses",
      contactsByCustomerId: "contactsByCustomerId",
      customerLicenses: "allCustomerlicenses",
      subcustomers: "allSubcustomers",
      subcustomerById: "subcustomerById",
      subcustomersByCustomer: "subcustomersByCustomer",
      customerlicensesByCustomerId: "customerlicensesByCustomerId",
      customerlicensesBySubcustomerId: "customerlicensesBySubcustomerId",
      activeUniqueCustomerlicensesByCustomerId:
        "activeUniqueCustomerlicensesByCustomerId",
      activeUniqueCustomerlicensesBySubcustomerId:
        "activeUniqueCustomerlicensesBySubcustomerId",
      allTemplates: "allTemplates",
    }),
    getApplicationsString: function () {
      var retString = "Applications sold: ";
      var applications = [];
      var licenses = this.customerlicensesByCustomerId(
        this.customer.customerid
      );
      licenses.forEach((l) => {
        var template = this.templateById(l.templateid);
        var application = this.applicationById(template.applicationid);
        if (application) {
          if (!applications.includes(application.name))
            applications.push(application.name);
        }
      });
      for (let i = 0; i < applications.length; i++) {
        retString += applications[i];
        if (i + 1 != applications.length) retString += ", ";
      }
      return retString;
    },
    getTotalCustomersString: function () {
      var amount = this.subcustomersByCustomer(this.customer.customerid).length;
      return "Total Customers: {0}".format(amount);
    },
    getTotalLicensesSoldString: function () {
      var licenses = this.activeUniqueCustomerlicensesByCustomerId(
        this.customer.customerid
      );
      var amount = Number(0);
      if (licenses) {
        licenses.forEach((l) => {
          amount += Number(l.amount);
        });
      }
      return "Total Licenses Sold: {0}".format(amount);
    },
    getTotalRevenueString: function () {
      var licenses = this.activeUniqueCustomerlicensesByCustomerId(
        this.customer.customerid
      );
      var price = parseFloat(0);
      var priceMonthly = parseFloat(0);
      if (licenses) {
        licenses.forEach((l) => {
          if (l.subscription) {
            priceMonthly += parseFloat(l.finalprice) * Number(l.amount);
          } else {
            price += parseFloat(l.finalprice) * Number(l.amount);
          }
        });
      }
      return "Total Revenue: {0}€ | {1}€/Month".format(
        price.toFixed(2),
        priceMonthly.toFixed(2)
      );
    },
    getSubcustomerLicenseStats: function () {
      var sub = this.subcustomersByCustomer(this.customer.customerid);
      var items = [];
      sub.forEach((s) => {
        var id = s.customerid;
        var name = s.name;
        var licenses = this.getSubcustomerLicensesCount(s.customerid);
        var r = this.getSubcustomerRevenues(s.customerid);
        var revenue_onetime = r.price
          ? "{0}€".format(r.price.toFixed(2))
          : "0€";
        var revenue_monthly = r.priceMonthly
          ? "{0}€/Month".format(r.priceMonthly.toFixed(2))
          : "0€/Month";
        var maintenance = s.maintenance_active;

        if (
          (this.hideWithoutLicenses &&
            this.getSubcustomerLicensesCount(s.customerid)) ||
          !this.hideWithoutLicenses
        ) {
          items.push({
            customerid: id,
            customerof: s.customerof,
            name: name,
            city: s.city,
            street: s.street + " " + s.hnumber,
            maintenance: maintenance,
            licenses: licenses,
            revenue_onetime: revenue_onetime,
            revenue_monthly: revenue_monthly,
          });
        }
      });
      return items;
    },
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    search: {
      type: String,
      required: false,
      default: "",
    },
    hideWithoutLicenses: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    deleteCustomerDialog: false,
    subcustomer: null,
    selected: [],

    editedSubCustomer: null,
    licenseRequestDialog: false,

    expanded: false,
    partnerDialog: false,
    deleteDialog: false,
    disableDialog: false,
    activationDialog: false,
    contactsDialog: false,
    customerDialog: false,
    viewLicensesDialog: false,
    headers: [
      /*
      {
        text: "ID",
        align: "center",
        sortable: false,
        value: "id",
      },
      */
      {
        text: "Name",
        align: "center",
        sortable: false,
        value: "name",
      },
      {
        text: "City",
        align: "center",
        sortable: false,
        value: "city",
      },
      {
        text: "Street",
        align: "center",
        sortable: false,
        value: "street",
      },
      {
        text: "Maintenance",
        align: "center",
        sortable: false,
        value: "maintenance",
        width: "1%",
      },
      {
        text: "Licenses",
        align: "center",
        sortable: false,
        value: "licenses",
      },
      {
        text: "Revenue (One-time)",
        align: "center",
        sortable: false,
        value: "revenue_onetime",
      },
      {
        text: "Revenue (Monthly)",
        align: "center",
        sortable: false,
        value: "revenue_monthly",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
      deleteCustomer: "deleteCustomer",
      fetchContactsAsync: "fetchContactsAsync",
      fetchTemplatesAsync: "fetchTemplatesAsync",
      fetchCustomersAsync: "fetchCustomersAsync",
      fetchApplicationsAsync: "fetchApplicationsAsync",
      fetchSubcustomersAsync: "fetchSubcustomersAsync",
      fetchCustomerlicensesAsync: "fetchCustomerlicensesAsync",
      deleteSubcustomer: "deleteSubcustomer",
      showSnackbar: "showSnackbar",
    }),
    editItem(subCustomer) {
      this.subcustomer = this.subcustomerById(subCustomer.customerid);
      this.customerDialog = true;
    },
    addLicense(subCustomer) {
      this.subcustomer = subCustomer;
      this.licenseRequestDialog = true;
    },
    viewLicenses(subCustomer) {
      this.subcustomer = subCustomer;
      this.viewLicensesDialog = true;
    },
    addCustomerAsAdmin() {
      this.subcustomer = { customerof: this.customer.customerid };
    },
    debug: function () {
      console.log("contacts:");
      var contacts = this.contactsByCustomerId(this.customer.customerid);
      console.log(contacts);
    },
    isShown: function () {
      /* var search = this.search.toLowerCase();
      if (this.customer.name.toLowerCase().includes(search)) return true;
      return false; */
      var shown = false;
      var search = this.search.toLowerCase();
      if (this.customer.name.toLowerCase().includes(search)) shown = true;
      if (
        this.customer.country &&
        this.customer.country.toLowerCase().includes(search)
      )
        shown = true;
      if (
        this.customer.state &&
        this.customer.state.toLowerCase().includes(search)
      )
        shown = true;
      if (
        this.customer.street &&
        this.customer.street.toLowerCase().includes(search)
      )
        shown = true;

      this.contactsByCustomerId(this.customer.customerid).forEach((contact) => {
        if (contact.email.toLowerCase().includes(search)) shown = true;
        if (
          (
            contact.forename.toLowerCase() +
            " " +
            contact.surname.toLowerCase()
          ).includes(search)
        )
          shown = true;
        if (contact.phone.toLowerCase().includes(search)) shown = true;
      });
      return shown;
    },
    getSubcustomerLicensesCount: function (subcustomerid) {
      var licenses =
        this.activeUniqueCustomerlicensesBySubcustomerId(subcustomerid);
      var amount = 0;
      if (licenses) {
        //return licenses.reduce((f, s) => f + s.amount, 0)
        licenses.forEach((l) => {
          amount += Number(l.amount);
        });
      }
      return amount;
    },
    getSubcustomerRevenues: function (subcustomerid) {
      var licenses =
        this.activeUniqueCustomerlicensesBySubcustomerId(subcustomerid);
      var price = parseFloat(0);
      var priceMonthly = parseFloat(0);
      if (licenses) {
        licenses.forEach((l) => {
          if (l.subscription) {
            priceMonthly += parseFloat(l.finalprice) * Number(l.amount);
          } else {
            price += parseFloat(l.finalprice) * Number(l.amount);
          }
        });
      }
      return {
        price: price,
        priceMonthly: priceMonthly,
      };
    },
    disablePartner: function () {
      console.log("disbling partner...");
    },
    deletePartner: function () {
      console.log("deleting partner...");
      console.log(this.customer);
      const projectid = this.customer.projectid;
      this.deleteCustomer(this.customer).then((error) => {
        console.log(projectid);
        if (error) {
          this.showSnackbar(`Partner could not be deleted. Error: ${error}`);
        } else {
          this.showSnackbar("Partner has been deleted");
        }
      });
    },
    canDeletePartner: function () {
      return true;
    },
    deleteSubustomer() {
      Promise.all(
        this.selected.map(
          async (subcustomer) => await this.deleteSubcustomer(subcustomer)
        )
      ).then((res) => {
        const err = res.find((x) => x !== undefined);
        if (err) {
          this.showSnackbar("Customer could not be deleted. Error: " + err);
        } else {
          this.showSnackbar("Customer has been deleted");
        }
        this.selected = [];
        this.deleteCustomerDialog = false;
      });
    },
  },
  async mounted() {
    await this.fetchCustomersAsync();
    await this.fetchTemplatesAsync();
    await this.fetchContactsAsync();
    await this.fetchApplicationsAsync();
    await this.fetchSubcustomersAsync();
    await this.fetchCustomerlicensesAsync();
  },
};
</script>
<style>
.custom_card {
  border-radius: 5px !important;
  border-width: 2px !important;
  border-color: #d0d0d0 !important;
}
.custom_card_title {
  font-size: 1.3rem;
}
.menu_item {
  font-size: 0.9rem;
}
</style>