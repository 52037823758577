<template>
  <v-card>
    <v-card-title>
      <menuTitle :title="customer ? 'Edit Partner' : 'New Partner'"></menuTitle>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" align="left">
          <v-subheader>Company Information</v-subheader>
        </v-col>

        
        <v-col cols="12" v-if="customer">
          <v-text-field
            outlined
            label="Project Id String*"
            v-model="tmpCustomer.projectid_string"
            :rules="[requiredRule]"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field
            outlined
            label="Company Name*"
            v-model="tmpCustomer.name"
            :rules="[requiredRule]"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            label="GP-CODE*"
            v-model="tmpCustomer.gp_code"
            :rules="[requiredRule]"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field
            outlined
            label="Street / P.O. Box"
            v-model="tmpCustomer.street"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            label="Building Name"
            v-model="tmpCustomer.hnumber"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            outlined
            label="Zip / Postal"
            v-model="tmpCustomer.zip"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            label="City"
            v-model="tmpCustomer.city"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            label="State / Province"
            v-model="tmpCustomer.state"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            label="Country"
            v-model="tmpCustomer.country"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            outlined
            label="Website"
            v-model="tmpCustomer.website"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            label="Telephone"
            v-model="tmpCustomer.tel"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" align="left">
          <v-subheader>Additional Information</v-subheader>
        </v-col>

        <v-col cols="12">
          <v-text-field
            outlined
            label="Notes"
            v-model="tmpCustomer.notes"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined @click="$emit('done')">
        <span>Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        @click="customer ? patchCustomer() : postCustomer()"
      >
        <span v-if="customer">Save</span>
        <span v-else>Add</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
export default {
  components: { menuTitle },
  name: "partnerDialog",
  props: {
    customer: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      customers: "allCustomers",
      users: "allUsers",
      contactsByCustomerId: "contactsByCustomerId",
      newCustomer: "newCustomer",
    }),
    contacts: function () {
      if (!this.customer) {
        return [];
      }
      return this.contactsByCustomerId(this.customer.customerid);
    },
  },
  data: () => ({
    changed: -1,
    tmpCustomer: {},
    requiredRule: (value) => !!value || "Required",
  }),
  updated() {
    this.changed++;
  },
  mounted() {
    if (this.customer) {
      this.tmpCustomer = JSON.parse(JSON.stringify(this.customer));
    } else {
      this.tmpCustomer = JSON.parse(JSON.stringify(this.newCustomer));
    }
  },
  methods: {
    ...mapActions({
      post: "postCustomer",
      patch: "patchCustomer",
      deleteCustomer: "deleteCustomer",
      showSnackbar: "showSnackbar",
      createProject: "createProject",
      setProjectIdsForCustomer: "setProjectIdsForCustomer",
    }),
    patchCustomer: function () {
      if (this.changed == 0) {
        this.$emit("done");
        return;
      }
      this.patch(this.tmpCustomer).then((error) => {
        if (error) {
          this.showSnackbar(
            "Partner could not be updated. Error: {0}".format(error)
          );
        } else {
          this.showSnackbar("Partner has been updated");
          this.$emit("done");
        }
      });
    },
    postCustomer: function () {
      this.post(this.tmpCustomer).then((customer) => {
        if (customer) {
          console.log({ name: customer.name, customerid: customer.customerid });
          this.createProject({
            name: customer.name,
            customerid: customer.customerid,
            gpcode: this.tmpCustomer.gp_code,
          })
            .then((val) => {
              customer.projectid = val.projectid;
              customer.projectid_string = val.projectid_string;
              this.setProjectIdsForCustomer(customer);
              this.showSnackbar("Partner has been added");
              this.tmpCustomer = JSON.parse(JSON.stringify(this.newCustomer));
              this.$emit("done");
            })
            .catch((e) => {
              this.deleteCustomer(customer);
              console.log(e.data);
              this.showSnackbar(
                `Partner could not be added: ${e.data}`
              );
            });
        } else {
          this.showSnackbar(`Partner could not be added`);
        }
      });
    },
  },
};
</script>

<style>
</style>